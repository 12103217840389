import React from "react"
import Layout from "../components/Layout"
import aboutStyle from "./About.module.scss"
import Img from "gatsby-image"
import { Helmet } from "react-helmet"
import AboutImg from "../components/Images//About"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import PageTransition from "gatsby-plugin-page-transitions"
import MobilePhoto from "../components/Images/MobilePhoto"

// export const query = graphql`
//   query {
//     file(relativePath: { eq: "photos/IMG_2583.jpg" }) {
//       childImageSharp {
//         # Specify the image processing specifications right in the query.
//         # Makes it trivial to update as your page's design changes.

//         fluid(maxWidth: 400) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `

// const TechStack = () => {
//   console.log(TechStack)

// }

const About = props => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "photos/tech" }
        }
      ) {
        edges {
          node {
            id
            base
            childImageSharp {
              fixed(width: 60) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `)
  return (
    <PageTransition
      defaultStyle={{
        transition: "left 700ms cubic-bezier(0.47, 0, 0.75, 0.72)",
        left: "100%",
        position: "absolute",
        width: "100%",
      }}
      transitionStyles={{
        entering: { left: "0%" },
        entered: { left: "0%" },
        exiting: { left: "100%" },
      }}
      transitionTime={500}
    >
      <div>
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.7.2/css/all.css"
          integrity="sha384-fnmOCqbTlWIlj8LyTjo7mOUStjsKC4pOpQbqyi7RrhN7udi9RwhKkMHpvLbHG9Sr"
          crossorigin="anonymous"
        />
        <Helmet>
          <meta charSet="utf-8" />
          <title>William Cheshier</title>
          <link rel="canonical" href="https://williamcheshier.com/about" />
        </Helmet>

        <Layout>
          <div className={aboutStyle.hero}>
            <h1 className={aboutStyle.title}>About Me</h1>
          </div>
          <div className={aboutStyle.mobileHeader}>
            <h1 className={aboutStyle.mobileTitle}>About Me</h1>
            <MobilePhoto />
          </div>
          <div className={aboutStyle.main}>
            <div className={aboutStyle.left}>
              <div className={aboutStyle.imagen}>
                {" "}
                <AboutImg className={aboutStyle.img} />
              </div>

              <div className={aboutStyle.text}>
                <div className={aboutStyle.iconos}>
                  <i className="fas fa-map-marker-alt fa-sm"></i>
                  <p>Location: Seattle, WA </p>
                </div>
                <div className={aboutStyle.iconos}>
                  <i className="fas fa-graduation-cap fa-sm"></i>

                  <p>Washington State University</p>
                </div>
                <div className={aboutStyle.iconos}>
                  <i className="fas fa-language fa-sm"></i>
                  <p>Languages: English & Spanish</p>
                </div>
              </div>
            </div>
            <div className={aboutStyle.styledRight}>
              <h1 className={aboutStyle.headline}>Welcome!</h1>
              <div className={aboutStyle.bodyText}>
                <p>
                  Hey! Glad to see you here on my website. My name is William
                  Cheshier and I am a web professional. Some of my skills I
                  possess that you may find useful for your business include
                  being a developer, writer, marketer, and consultant. I take
                  pride in my work and thoroughly enjoy the process of using
                  technology to help people and companies solve problems.
                </p>
                <p>
                  In my freetime, I have a passion for small businesses and
                  helping them through my freelance business{" "}
                  <a
                    href="https://www.cheshtech.com"
                    target="_blank"
                    style={{ marginRight: "5px", textDecoration: "none" }}
                  >
                    CheshTech.
                  </a>
                  However, my ultimate goal is to develop my developer skills
                  and work on meaningful projects. I love the problem solving
                  and logic that goes into coding and is why I would love to
                  contribute on the development team of a quality organization.
                </p>
                <p>
                  How about a bit about myself and the person you might be
                  hiring/working with? My passions include consistent learning,
                  reading, traveling, craft beer, baseball, being with friends &
                  family, and of course enjoying a bit of Netflix from time to
                  time! I'm a hard and dedicated worker who thrives in hard
                  working, team-friendly environments. Thanks for taking the
                  time to learn a bit about me!
                </p>
              </div>
              <div className={aboutStyle.logoContainer}>
                <h1 className={aboutStyle.headline}>Technology</h1>
                <div>
                  {data.allFile.edges.map(image => (
                    <Img
                      className={aboutStyle.logo}
                      fixed={image.node.childImageSharp.fixed}
                    />
                  ))}
                </div>
              </div>

              <div className={aboutStyle.socialContainer}>
                <div className={aboutStyle.contactColumn}>
                  <Link to="/contact" className={aboutStyle.contact}>
                    Let's Work Together!
                  </Link>
                </div>
                <p>Follow me on social media</p>
                <a
                  className={aboutStyle.social}
                  href="https://github.com/wchesh24"
                  target="_blank"
                >
                  <i className="fab fa-github"></i>
                </a>
                <a
                  className={aboutStyle.social}
                  href="https://www.instagram.com/thechesh24/"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  className={aboutStyle.social}
                  href="https://www.facebook.com/will.cheshier.7?ref=bookmarks"
                  target="_blank"
                >
                  <i className="fab fa-facebook"></i>
                </a>
                <a
                  className={aboutStyle.social}
                  href="https://twitter.com/TheChesh24"
                >
                  <i class="fab fa-twitter"></i>
                </a>
              </div>
            </div>
          </div>
        </Layout>
      </div>
    </PageTransition>
  )
}

export default About
// <img
// alt="javascript"
// className={aboutStyle.logo}
// src="../photos/tech/javascript.png"
// />
// <img
// alt="Gatsby"
// className={aboutStyle.logo}
// src="../photos/tech/gatsby.svg"
// />
// <img
// alt="GraphQl"
// className={aboutStyle.logo}
// src="../photos/tech/graphql.png"
// />
// <img
// alt="React"
// className={aboutStyle.logo}
// src="../photos/tech/reactjs.png"
// />

// <img
// alt="nodeJs"
// className={aboutStyle.logo}
// src="../photos/tech/nodejs.png"
// />
// <img
// alt="Mongo"
// className={aboutStyle.logoMongo}
// src="../photos/tech/bigmongo.png"
// />
// <img
// alt="Wordpress"
// className={aboutStyle.logo}
// src="../photos/tech/wordpress.png"
// />
// <img
// alt="HTML"
// className={aboutStyle.logo}
// src="../photos/tech/html5.png"
// />
// <img
// alt="CSS"
// className={aboutStyle.logo}
// src="../photos/tech/css3.png"
// />
// <img
// alt="Sass"
// className={aboutStyle.logo}
// src="../photos/tech/sass.png"
// />

// <img
//   alt="javascript"
//   className={aboutStyle.logo}
//   src="./logos/javascript.svg"
// />
// <img
//   alt="Gatsby"
//   className={aboutStyle.logo}
//   src="./logos/gatsby.svg"
// />
// <img
//   alt="GraphQl"
//   className={aboutStyle.logo}
//   src="./logos/graphql.svg"
// />
// <img
//   alt="React"
//   className={aboutStyle.logo}
//   src="./logos/react.svg"
// />

// <img
//   alt="nodeJs"
//   className={aboutStyle.logo}
//   src="./logos/nodejs-icon.svg"
// />
// <img
//   alt="Mongo"
//   className={aboutStyle.logo}
//   src="./logos/mongodb.png"
// />
// <img
//   alt="Wordpress"
//   className={aboutStyle.logo}
//   src="./logos/wordpress-color.svg"
// />
// <img
//   alt="HTML"
//   className={aboutStyle.logo}
//   src="./logos/html.png"
// />
// <img
//   alt="CSS"
//   className={aboutStyle.logo}
//   src="./logos/css.png"
// />
// <img
//   alt="Sass"
//   className={aboutStyle.logo}
//   src="./logos/sass-1.svg"
// />
